/* CSS */
.forgotPasswordButton {
  font-size: 16px;
  font-weight: 200;
  font-weight: bold;
  letter-spacing: 1px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-ser;
  padding: 13px 20px 13px;
  outline: 0;
  border: 1px solid black;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.forgotPasswordButton:after {
  content: "";
  background-color: #FFF600;
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 7px;
  left: 7px;
  transition: 0.2s;
}

.forgotPasswordButton:hover:after {
  top: 0px;
  left: 0px;
}

@media (min-width: 768px) {
  .forgotPasswordButton {
    padding: 13px 50px 13px;
  }
}
