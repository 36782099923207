/* CSS */
.LoginButton,
.LoginButton:after {
  width: 150px;
  height: 76px;
  line-height: 78px;
  font-size: 20px;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-ser;
  background: linear-gradient(45deg, transparent 5%, #b21807 5%);
  border: 0;
  color: #000000;
  letter-spacing: 2px;
  border: #000000 2px;
  outline: transparent;
  position: relative;
  touch-action: manipulation;
}

.LoginButton:after {
  --slice-0: inset(50% 50% 50% 50%);
  --slice-1: inset(80% -6px 0 0);
  --slice-2: inset(50% -6px 30% 0);
  --slice-3: inset(10% -6px 85% 0);
  --slice-4: inset(40% -6px 43% 0);
  --slice-5: inset(80% -6px 5% 0);

  content: "cheese";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    transparent 3%,
    #b21807 3%,
    #b21807 5%,
    #b21807 5%
  );

  clip-path: var(--slice-0);
}

.LoginButton:hover:after {
  animation: 1s glitch;
  animation-timing-function: steps(2, end);
}

@keyframes glitch {
  0% {
    clip-path: var(--slice-1);
    transform: translate(-20px, -10px);
  }
  10% {
    clip-path: var(--slice-3);
    transform: translate(10px, 10px);
  }
  20% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 10px);
  }
  30% {
    clip-path: var(--slice-3);
    transform: translate(0px, 5px);
  }
  40% {
    clip-path: var(--slice-2);
    transform: translate(-5px, 0px);
  }
  50% {
    clip-path: var(--slice-3);
    transform: translate(5px, 0px);
  }
  60% {
    clip-path: var(--slice-4);
    transform: translate(5px, 10px);
  }
  70% {
    clip-path: var(--slice-2);
    transform: translate(-2px, 10px);
  }
}

@media (min-width: 768px) {
  .LoginButton,
  .LoginButton:after {
    width: 200px;
    height: 86px;
    line-height: 88px;
  }
}

/* CSS */
.SignUpButton,
.SignUpButton:after {
  width: 150px;
  height: 76px;
  line-height: 78px;
  font-size: 20px;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-ser;
  background: linear-gradient(45deg, transparent 5%, #b21807 5%);
  border: 0;
  color: #000000;
  letter-spacing: 2px;
  border: #000000 2px;
  outline: transparent;
  position: relative;
  touch-action: manipulation;
}

.SignUpButton:after {
  --slice-0: inset(50% 50% 50% 50%);
  --slice-1: inset(80% -6px 0 0);
  --slice-2: inset(50% -6px 30% 0);
  --slice-3: inset(10% -6px 85% 0);
  --slice-4: inset(40% -6px 43% 0);
  --slice-5: inset(80% -6px 5% 0);

  content: "pepperoni";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    transparent 3%,
    #b21807 3%,
    #b21807 5%,
    #b21807 5%
  );

  clip-path: var(--slice-0);
}

.SignUpButton:hover:after {
  animation: 1s glitch;
  animation-timing-function: steps(2, end);
}

@keyframes glitch {
  0% {
    clip-path: var(--slice-1);
    transform: translate(20px, -10px);
  }
  10% {
    clip-path: var(--slice-3);
    transform: translate(10px, 10px);
  }
  20% {
    clip-path: var(--slice-1);
    transform: translate(10px, 10px);
  }
  30% {
    clip-path: var(--slice-3);
    transform: translate(0px, 5px);
  }
  40% {
    clip-path: var(--slice-2);
    transform: translate(5px, 0px);
  }
  50% {
    clip-path: var(--slice-3);
    transform: translate(5px, 0px);
  }
  60% {
    clip-path: var(--slice-4);
    transform: translate(5px, 10px);
  }
  70% {
    clip-path: var(--slice-2);
    transform: translate(-2px, 10px);
  }
}

@media (min-width: 768px) {
  .SignUpButton,
  .SignUpButton:after {
    width: 200px;
    height: 86px;
    line-height: 88px;
  }
}
