.body {
  background-color: #b21807;
}

.PizzaTContainer {
  background-color: #b21807;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-ser;
}

.PizzaTitle {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-ser;
  background-color: #b21807;
  font-size: calc((150vw) / 7);
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: 0;
}
